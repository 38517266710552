<template>
  <div>
    <div class="container">
      <div class="list list3">
        <div class="listitem" >
          <img src="@/assets/dyj.png" class="icon" />
          <input type="text" v-model="sn_num" class="input" placeholder="打印机编号(必填)" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" >
          <img src="@/assets/key.png" class="icon" />
          <input type="text"  v-model="key_num" class="input" placeholder="打印机密钥(必填)" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" >
          <img src="@/assets/bz.png" class="icon" />
          <input type="text" v-model="remark" class="input" placeholder="备注名称(必填)" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" >
          <img src="@/assets/llk.png" class="icon" />
          <input type="text" v-model="dcarnum" class="input" placeholder="流量卡号(选填)" />
        </div>
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="binding">绑定</div>         
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default { 
  async created() {
    /*let { name,idnum,mobile,number_car,picture,picture_ewm,wxdj_uuid } = this.$route.query;
    this.name = name;
    this.idnum = idnum;
    this.mobile = mobile;
    this.number_car = number_car;
    this.picture = picture;
    this.picture_ewm = picture_ewm;
    this.wxdj_uuid = wxdj_uuid;*/
    let { wxdj_uuid } = this.$route.query;
    this.wxdj_uuid = wxdj_uuid;

    let { data } = await this.axios.post(
      '/ctid/TlZhService/pz_pddyj',
      qs.stringify({})
    );
    if (data.flag === '0') {
      this.sn_num = data.data.sn_num;
      this.key_num = data.data.key_num;
      this.remark = data.data.remark;
      this.dcarnum = data.data.dcarnum;
    }
  },
  data() {
    return {     
      data: {
        kgflag: false
      },
      name:'',
      idnum:'',
      mobile:'',
      number_car:'',
      picture:'',
      picture_ewm:'',
      wxdj_uuid:'',
      sn_num: '',
      key_num: '',
      remark: '',
      dcarnum: '',
      flag: false
    };
  },
  methods: {
    async binding() {
      if (isEmpty(this.sn_num)) {
        Toast('请填写打印机编号!');
        return;
      }else if (isEmpty(this.key_num)) {
        Toast('请填写打印机密钥!');
        return;
      }else if (isEmpty(this.remark)) {
        Toast('请填写备注名称!');
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/TlZhService/pz_tjdyj',
        qs.stringify({
          sn_num: this.sn_num,
          key_num: this.key_num,
          remark: this.remark,
          dcarnum: this.dcarnum,
          wxdj_uuid: this.wxdj_uuid
        })
      );
      if (data.flag === '0') {
        Dialog({ message: "<img src='/img/cg.png' width=100px height=60px/></br>"+data.message });       
      }else {
        Dialog({ message: "<img src='/img/sb.png' width=100px height=60px/></br>"+data.message });
      }
      //this.$router.push('/assistregister/submit_success?name=' + this.name +'&idnum=' + this.idnum +'&mobile=' + this.mobile +'&number_car=' + this.number_car +'&picture=' + this.picture +'&picture_ewm=' + this.picture_ewm +'&wxdj_uuid=' + this.wxdj_uuid);
      this.$router.push('/assistregister/submit_success?wxdj_uuid=' + this.wxdj_uuid);

    }
    
  }
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .ll {
    width: 60%;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }   
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem1 {
    overflow: hidden;
    height: 270px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .iconkg {
        margin-top: 34px;
        width: 320px;     
        float: right;
        margin-right: 34px;
      }

      
    }
    
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>